import { TargetsActionType } from "./action";
import { TargetsActionTypes } from "./constants";

const INIT_STATE = {
    loading: false,
    quaterlytargets: { years: [], targetYear: [{ data: { q1: [{ title: "", completed: false }], q2: [{ title: "", completed: false }], q3: [{ title: "", completed: false }], q4: [{ title: "", completed: false }], } }] }
}
type State = {
    loading?: boolean,
    quaterlytargets?: any
}
const Targets = (state: State = INIT_STATE, action: TargetsActionType) => {
    switch (action.type) {
        case TargetsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TargetsActionTypes.GET_QUARTERLY_TARGETS: {
                    return {
                        ...state,
                        quaterlytargets: action.payload.data,
                    };
                };
                case TargetsActionTypes.ADD_QUARTERLY_TARGETS: {
                    return {
                        ...state,
                        quaterlytargets: action.payload.data,
                    };
                };
                case TargetsActionTypes.UPDATE_QUARTERLY_TARGETS: {
                    return {
                        ...state,
                        quaterlytargets: action.payload.data,
                    };
                };
                case TargetsActionTypes.DELETE_QUARTERLY_TARGETS: {
                    return {
                        ...state,
                        quaterlytargets: action.payload.data,
                    };
                };
                default:
                    return { ...state, };

            };
        case TargetsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TargetsActionTypes.GET_QUARTERLY_TARGETS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };
                };
                case TargetsActionTypes.ADD_QUARTERLY_TARGETS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };
                };
                case TargetsActionTypes.UPDATE_QUARTERLY_TARGETS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };
                };
                case TargetsActionTypes.DELETE_QUARTERLY_TARGETS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };
                };
                default:
                    return { ...state, };
            };


        case TargetsActionTypes.GET_QUARTERLY_TARGETS:
            {
                return { ...state, loading: true, };
            };
        case TargetsActionTypes.ADD_QUARTERLY_TARGETS:
            {
                return { ...state, loading: true, };
            };
        case TargetsActionTypes.UPDATE_QUARTERLY_TARGETS:
            {
                return { ...state, loading: true, };
            };
        case TargetsActionTypes.DELETE_QUARTERLY_TARGETS:
            {
                return { ...state, loading: true, };
            };
        default:
            return { ...state };
    }
};
export default Targets;